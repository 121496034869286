<template>
  <div id="mian">
    <div class="header">
      <span>基本信息</span>
      <span>结算信息</span>
      <span id="fzcolor">渠道信息</span>
      <span>产品信息</span>
      <span>配置信息</span>
      <span v-if="checkStatus == 'PASS'">终端信息</span>
    </div>
    <div v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE'">
      <div v-if="channelType == 0">
        <div class="desc">
          <h5 class="jsxx">支付产品</h5>
        </div>
        <div class="tab1">
          <table class="tab">
            <tr class="tab_title">
              <td style="padding-left: 10px">
                <p>产品类型</p>
              </td>
              <td>
                <p>产品通道</p>
              </td>
              <td>
                <p>退款策略</p>
              </td>
              <td>
                <p>结算周期</p>
              </td>
              <td>
                <p>结算费率(%)</p>
                <p>大额费率(%)</p>
              </td>
              <td>
                <p>单日限额(元)</p>
              </td>
              <td>
                <p>单笔最小限额(元)</p>
                <p>单笔最大限额(元)</p>
              </td>
              <td>
                <p>风控阈值(%)</p>
              </td>
              <td>
                <p>统计粒度</p>
              </td>
              <td>
                <p>风控状态</p>
              </td>
              <td>
                <p>操作</p>
              </td>
            </tr>
            <tr v-for="(v, i) in payProducts" :key="i">
              <td style="padding-left: 10px">
                <p>{{ v.productName }}</p>
              </td>
              <td>
                <p>{{ v.payChannelName }}</p>
              </td>
              <td>
                <p>
                  {{
                    v.refundStrategy == "WITH_SERVICE_CHARGE"
                      ? "退手续费"
                      : v.refundStrategy == "WITHOUT_SERVICE_CHARGE"
                      ? "不退手续费"
                      : ""
                  }}
                </p>
              </td>
              <td>
                <p>{{ v.settleCycle }}</p>
              </td>
              <td>
                <p>{{ v.settleRate }}</p>
                <p>{{ v.largeRate }}</p>
              </td>
              <td>
                <p>{{ v.dayLimit | formatMoney }}</p>
              </td>
              <td>
                <p>{{ v.singleMinimumLimit | formatMoney }}</p>
                <p>{{ v.singleMaximumLimit | formatMoney }}</p>
              </td>
              <td>
                <p>{{ v.riskThreshold }}</p>
              </td>
              <td>
                <p v-if="v.riskGranularity==0">常规</p>
                <p v-if="v.riskGranularity==1">1分钟</p>
                <p v-if="v.riskGranularity==3">3分钟</p>
                <p v-if="v.riskGranularity==5">5分钟</p>
              </td>
              <td>
                <p v-show="OFFON_EDIT != 'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/EDIT'"> {{ v.riskStatus==true ? "已开启" : "已关闭" }}</p>
                <p v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/EDIT'">
                  <el-switch
                    v-model="v.riskStatus"
                    @change="setStatus(v)"
                    active-color="#13ce66"
                  >
                  </el-switch>
                </p>
              </td>
              <td>
                <p>
                  <i
                    v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/EDIT'"
                    class="lianjie"
                    @click="editPayTab(v)"
                    >编辑</i
                  >
                  <i
                    v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/DELETE'"
                    class="lianjie"
                    @click="delPayTab(v.productId)"
                    >删除</i
                  >
                </p>
              </td>
            </tr>
          </table>
        </div>
        <h6
          v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/ADD'"
          class="xzjsxx"
          @click="addPayTab"
        >
          <span>+</span> 新增支付产品
        </h6>
        <div class="desc">
          <h5 class="jsxx">代付产品</h5>
        </div>
        <div class="tab1">
          <table class="tab">
            <tr class="tab_title">
              <td style="padding-left: 10px">
                <p>产品类型</p>
              </td>
              <td>
                <p>产品通道</p>
              </td>
              <td>
                <p>费率(%)</p>
              </td>
              <td>
                <p>对公代付(元/笔)</p>
              </td>
              <td>
                <p>对私代付(元/笔)</p>
              </td>
              <td>
                <p>单日限额(元)</p>
              </td>
              <td>
                <p>单笔限额(元)</p>
              </td>
              <td>
                <p>操作</p>
              </td>
            </tr>
            <tr v-for="(v, i) in agnpayProducts" :key="i">
              <td style="padding-left: 10px">
                <p>
                  {{
                    v.payType == "BANK_PAY_DAILY_ORDER"
                      ? "朝夕付"
                      : v.payType == "BANK_PAY_MAT_ENDOWMENT_ORDER"
                      ? "任意付"
                      : ""
                  }}
                </p>
              </td>
              <td>
                <p>{{ v.agnpayChannelName }}</p>
              </td>
              <td>
                <p>{{ v.payRate }}</p>
              </td>
              <td>
                <p>{{ v.payCostPublic | formatMoney }}</p>
              </td>
              <td>
                <p>{{ v.payCostPrivate | formatMoney }}</p>
              </td>
              <td>
                <p>{{ v.dayLimit | formatMoney }}</p>
              </td>
              <td>
                <p>{{ v.singleLimit | formatMoney }}</p>
              </td>
              <td>
                <p>
                  <i
                    v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/EDIT'"
                    class="lianjie"
                    @click="editAgnPayTab(v, i)"
                    >编辑</i
                  >
                  <i
                    v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/DELETE'"
                    class="lianjie"
                    @click="delAgnPayTab(v.payType)"
                    >删除</i
                  >
                </p>
              </td>
            </tr>
          </table>
        </div>
        <h6
          v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/ADD'"
          class="xzjsxx"
          @click="addAgnPayTab"
        >
          <span>+</span> 新增代付产品
        </h6>
        <div class="desc">
          <h5 class="jsxx">分账产品</h5>
        </div>
        <div class="tab1">
          <table class="tab">
            <tr class="tab_title">
              <td style="padding-left: 10px">
                <p>产品类型</p>
              </td>
              <td>
                <p>产品通道</p>
              </td>
              <td>
                <p>结算费率(%)</p>
              </td>
              <td>
                <p>结算成本(元/笔)</p>
              </td>
              <td>
                <p>操作</p>
              </td>
            </tr>
            <tr v-for="(v, i) in allocProducts" :key="i">
              <td style="padding-left: 10px">
                <p>
                  {{
                    v.settleType == "AUTO"
                      ? "自动结算"
                      : v.settleType == "MANUAL"
                      ? "手工结算"
                      : v.settleType == "MANUAL_INSTANT"
                      ? "手工即时结算"
                      : ""
                  }}
                </p>
              </td>
              <td>
                <p>{{ v.allocChannelName }}</p>
              </td>
              <td>
                <p>{{ v.settleRate }}</p>
              </td>
              <td>
                <p>{{ v.settleCost | formatMoney }}</p>
              </td>
              <td>
                <p>
                  <i
                    v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/EDIT'"
                    class="lianjie"
                    @click="editAllTab(v, i)"
                    >编辑</i
                  >
                  <i
                    v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/DELETE'"
                    class="lianjie"
                    @click="delAllTab(v.settleType)"
                    >删除</i
                  >
                </p>
              </td>
            </tr>
          </table>
        </div>
        <h6
          v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/ADD'"
          class="xzjsxx"
          @click="addAllTab"
        >
          <span>+</span> 新增分账产品
        </h6>
      </div>
      <div v-if="channelType == 1">
        <div class="desc">
          <h5 class="jsxx">电子账簿信息</h5>
        </div>
        <div class="tab1">
          <table class="tab">
            <tr class="tab_title">
              <td style="padding-left: 10px">
                <p>户名</p>
              </td>
              <td>
                <p>账号</p>
              </td>
              <td>
                <p>银行</p>
              </td>
              <td>
                <p>支行</p>
              </td>
              <td>
                <p>开户地</p>
              </td>
              <td>
                <p>操作</p>
              </td>
            </tr>
            <tr v-if="AccBooChannel.accountName != null && AccBooChannel.accountName != ''">
              <td style="padding-left: 10px">
                <p>{{ AccBooChannel.accountName }}</p>
              </td>
              <td>
                <p>{{ AccBooChannel.accountNo }}</p>
              </td>
              <td>
                <p>{{ AccBooChannel.accountBankName }}</p>
              </td>
              <td>
                <p>{{ AccBooChannel.accountBranchBankName }}</p>
              </td>
              <td>
                <p>{{ AccBooChannel.accountOpen }}</p>
              </td>
              <td>
                <p>
                  <i
                    v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/EDIT'"
                    class="lianjie"
                    @click="editElectronInfo(AccBooChannel,0)"
                    >编辑</i
                  >
                  <i
                    v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/DELETE'"
                    class="lianjie"
                    @click="delElectronInfo(0)"
                    >删除</i
                  >
                </p>
              </td>
            </tr>
          </table>
        </div>
        <h6 v-if="AccBooChannel.accountName == null || AccBooChannel.accountName == ''"
          v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/ADD'"
          class="xzjsxx"
          @click="addElectronInfo(0)"
        >
          <span>+</span> 新增电子账簿信息
        </h6>
        <div class="desc">
          <h5 class="jsxx">出金账户信息</h5>
        </div>
        <div class="tab1">
          <table class="tab">
            <tr class="tab_title">
              <td style="padding-left: 10px">
                <p>收款账号</p>
              </td>
              <td>
                <p>户名</p>
              </td>
              <td>
                <p>开户行</p>
              </td>
              <td>
                <p>操作</p>
              </td>              
            </tr>
            <tr v-if="AccBooChannel.receiveAccountNo != null &&  AccBooChannel.receiveAccountNo != ''">
              <td style="padding-left: 10px">
                <p>{{ AccBooChannel.receiveAccountNo }}</p>
              </td>
              <td>
                <p>{{ AccBooChannel.receiveAccountName }}</p>
              </td>
              <td>
                <p>{{ AccBooChannel.receiveAccountBankName }}</p>
              </td>
              <td>
                <p>
                  <i
                    v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/EDIT'"
                    class="lianjie"
                    @click="editElectronInfo(AccBooChannel,1)"
                    >编辑</i
                  >
                  <i
                    v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/DELETE'"
                    class="lianjie"
                    @click="delElectronInfo(1)"
                    >删除</i
                  >
                </p>
              </td>
            </tr>
          </table>
        </div>
        <h6  v-if="AccBooChannel.receiveAccountNo == null || AccBooChannel.receiveAccountNo == ''"
          v-auth="'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/ADD'"
          class="xzjsxx"
          @click="addElectronInfo(1)"
        >
          <span>+</span> 新增出金账户信息
        </h6>        
      </div>

      <div class="btn">
        <el-button @click="back" class="back">返回</el-button>
        <!-- <el-button @click="next" class="next" type="primary">完成</el-button> -->
      </div>
      <el-dialog
        @close="onCloseAccount"
        :title="editPayIndex !== null ? '修改支付产品' : '新增支付产品'"
        :visible.sync="userPayShow"
        width="490px"
      >
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>产品类型</div>
            <el-select
              v-model="payFormData.productId"
              placeholder="选择产品类型"
              @change="payDropChange"
              tabindex="1"
            >
              <el-option
                v-for="(v, i) in payDropLists"
                :key="i"
                :label="v.productName"
                :value="v.productId"
                :disabled="v.disabled"
              >
              </el-option>
            </el-select>
            <div v-if="addFlag">
              <div class="overAccount_ul_title">是否指向其他产品类型</div>

              <el-select
                v-model="payFormData.payCodeStatus"
                @change="changePayCode"
                placeholder="请选择"
                tabindex="2"
              >
                <el-option
                  v-for="v in directionList"
                  :key="v.value"
                  :label="v.label"
                  :value="v.value"
                >
                </el-option>
              </el-select>
            </div>

            <div class="overAccount_ul_title"><i>*</i>退款策略</div>
            <el-select
              v-model="payFormData.refundStrategy"
              placeholder="选择退款策略"
              tabindex="3"
            >
              <el-option
                label="退手续费"
                value="WITH_SERVICE_CHARGE"
              ></el-option>
              <el-option
                label="不退手续费"
                value="WITHOUT_SERVICE_CHARGE"
              ></el-option>
            </el-select>
            <div class="overAccount_ul_title"><i>*</i>结算费率(0-100%)</div>
            <!-- oninput="value=value.replace(/[^\d.]/g,'')" -->
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              min="0"
              max="100"
              v-model="payFormData.settleRate"
              placeholder="输入结算费率"
              tabindex="5"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>单日限额(元)</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              v-model="payFormData.dayLimit"
              min="0"
              placeholder="输入单日限额"
              tabindex="7"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>单笔最大限额(元)</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              v-model="payFormData.singleMaximumLimit"
              min="0"
              placeholder="输入单笔最大限额"
              tabindex="9"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>统计粒度</div>
            <el-select
              v-model="payFormData.riskGranularity"
              placeholder="选择统计粒度"
              tabindex="11"
            >
              <el-option
                v-for="(v, i) in fineness"
                :key="i"
                :label="v.title"
                :value="v.value"
                :disabled="v.disabled"
              >
              </el-option>
            </el-select>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title"><i>*</i>产品通道</div>
            <el-select
              v-model="payFormData.payChannelId"
              placeholder="选择产品通道"
              tabindex="2"
            >
              <el-option
                v-for="(v, i) in payDropListsChild"
                :key="i"
                :label="v.payChannelName"
                :value="v.payChannelId"
              >
              </el-option>
            </el-select>
            <div v-if="addFlag">
              <div class="overAccount_ul_title">
                <i v-if="payFormData.payCodeStatus == 1">*</i>其他产品类型
              </div>
              <el-select
                :disabled="payFormData.payCodeStatus == 1 ? false : true"
                v-model="payFormData.thisProductId"
                placeholder="请选择"
                tabindex="1"
              >
                <el-option
                  v-for="(v, i) in otherProduct"
                  :key="i"
                  :label="v.label"
                  :value="v.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="overAccount_ul_title"><i>*</i>结算周期</div>
            <el-select
              v-model="payFormData.settleCycle"
              placeholder="选择结算周期"
              tabindex="4"
            >
              <el-option label="T+1" value="T+1"></el-option>
              <el-option label="D+1" value="D+1"></el-option>
            </el-select>
            <div class="overAccount_ul_title">大额费率(0-100%)</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              min="0"
              max="100"
              v-model="payFormData.largeRate"
              placeholder="输入大额费率"
              tabindex="6"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>单笔最小限额(元)</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              v-model="payFormData.singleMinimumLimit"
              min="0"
              placeholder="输入单笔最小限额"
              tabindex="8"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>风控阈值(0-100%)</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              min="0"
              max="100"
              v-model="payFormData.riskThreshold"
              placeholder="输入风控阈值"
              tabindex="10"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>最小预警订单数</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              v-model="payFormData.riskMinOrderNum"
              min="0"
              placeholder="最小预警订单数"
              tabindex="12"
            ></el-input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="userPayShow = false;editPayIndex=null"
            >取 消</el-button
          >
          <el-button class="addBt" @click="sureAddPayTab" :disabled="buttonFlag">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :title="editAgnPayIndex !== null ? '修改代付产品' : '新增代付产品'"
        :visible.sync="hasPayShow"
        width="490px"
      >
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>产品类型</div>
            <el-select
              v-model="agnPayFormData.payType"
              placeholder="选择产品类型"
              @change="agnPayDropChange"
              tabindex="1"
            >
              <el-option
                v-for="(v, i) in AgnpayDropLists"
                :key="i"
                :label="AgnpayDropOption[v.payType]"
                :value="v.payType"
                :disabled="v.disabled"
              >
              </el-option>
            </el-select>
            <div class="overAccount_ul_title"><i>*</i>费率(0-100%)</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              min="0"
              max="100"
              v-model="agnPayFormData.payRate"
              placeholder="输入费率"
              tabindex="3"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>对私代付(元/笔)</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              v-model="agnPayFormData.payCostPrivate"
              min="0"
              placeholder="输入价格"
              tabindex="5"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>单笔限额(元)</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              v-model="agnPayFormData.singleLimit"
              min="0"
              placeholder="输入限额"
              tabindex="7"
            ></el-input>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title"><i>*</i>产品通道</div>
            <el-select
              v-model="agnPayFormData.agnpayChannelId"
              placeholder="选择产品通道"
              tabindex="2"
            >
              <el-option
                v-for="(v, i) in AgnpayDropListsChild"
                :key="i"
                :label="v.agnpayChannelName"
                :value="v.agnpayChannelId"
              >
              </el-option>
            </el-select>
            <div class="overAccount_ul_title"><i>*</i>对公代付(元/笔)</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              v-model="agnPayFormData.payCostPublic"
              min="0"
              placeholder="输入价格"
              tabindex="4"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>单日限额(元)</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              v-model="agnPayFormData.dayLimit"
              min="0"
              placeholder="输入限额"
              tabindex="6"
            ></el-input>
            <!--              <div class="overAccount_ul_title">IP白名单</div>
			          <el-input v-model="input" placeholder="输入IP"></el-input> -->
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="hasPayShow = false"
            >取 消</el-button
          >
          <el-button class="addBt" @click="sureAddAgnPayTab"  :disabled="buttonFlag">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :title="editAllIndex == null ? '新增分账产品' : '修改分账产品'"
        :visible.sync="accountShow"
        width="490px"
      >
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>产品类型</div>
            <el-select
              v-model="allFormData.settleType"
              placeholder="选择产品类型"
              @change="allDropChange"
              tabindex="1"
            >
              <el-option
                v-for="(v, i) in allDropLists"
                :key="i"
                :label="allDropOption[v.settleType]"
                :value="v.settleType"
                :disabled="v.disabled"
              >
              </el-option>
            </el-select>
            <div class="overAccount_ul_title"><i>*</i>费率(0-100%)</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              min="0"
              max="100"
              v-model="allFormData.settleRate"
              placeholder="输入费率"
              tabindex="3"
            ></el-input>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title"><i>*</i>产品通道</div>
            <el-select
              v-model="allFormData.allocChannelId"
              placeholder="选择产品通道"
              tabindex="2"
            >
              <el-option
                v-for="(v, i) in allDropListsChild"
                :key="i"
                :label="v.allocChannelName"
                :value="v.allocChannelId"
              >
              </el-option>
            </el-select>
            <div class="overAccount_ul_title"><i>*</i>结算成本(元/笔)</div>
            <el-input
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              v-model="allFormData.settleCost"
              min="0"
              placeholder="输入结算成本"
              tabindex="4"
            ></el-input>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="accountShow = false"
            >取 消</el-button
          >
          <el-button class="addBt" @click="sureAddAllTab"  :disabled="buttonFlag">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 电子账簿 -->
      <el-dialog
        :title="editElectronIndex !== null ? '编辑电子账簿信息' : '新增电子账簿信息'"
        :visible.sync="electronInfoShow"
        width="490px"
      >
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>户名</div>
            <el-input
              v-model="electronInfoData.accountName"
              maxlength="128"
              placeholder="输入户名"
              class="width100"
              tabindex="1"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>银行</div>
            <el-input
              v-model="electronInfoData.accountBankName"
              maxlength="128"
              placeholder="输入银行"
              class="width100"
              tabindex="5"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>开户地</div>
            <el-input
              v-model="electronInfoData.accountOpen"
              maxlength="2048"
              placeholder="输入开户地"
              class="width100"
              tabindex="7"
            ></el-input>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title"><i>*</i>账号</div>
            <el-input
              v-model="electronInfoData.accountNo"
              maxlength="128"
              placeholder="输入账号"
              class="width100"
              tabindex="5"
            ></el-input> 
            <div class="overAccount_ul_title"><i>*</i>支行</div>
            <el-input
              v-model="electronInfoData.accountBranchBankName"
              maxlength="2048"
              placeholder="输入支行"
              class="width100"
              tabindex="7"
            ></el-input>                       
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="electronInfoShow = false"
            >取 消</el-button
          >
          <el-button class="addBt" :disabled="buttonFlag" @click="sureAddElectronInfo(0)">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 出金账户信息 -->
      <el-dialog
        :title="editIncomeIndex !== null ? '编辑出金账户信息' : '新增出金账户信息'"
        :visible.sync="electronIncomeShow"
        width="490px"
      >
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>收款账号</div>
            <el-input
              v-model="electronInfoData.receiveAccountNo"
              maxlength="128"
              placeholder="输入收款账号"
              class="width100"
              tabindex="1"
            ></el-input>
            <div class="overAccount_ul_title"><i>*</i>开户行</div>
            <el-input
              v-model="electronInfoData.receiveAccountBankName"
              maxlength="128"
              placeholder="输入开户行"
              class="width100"
              tabindex="5"
            ></el-input>             
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title"><i>*</i>户名</div>
            <el-input
              v-model="electronInfoData.receiveAccountName"
              maxlength="128"
              placeholder="输入户名"
              class="width100"
              tabindex="5"
            ></el-input>                      
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="electronIncomeShow = false"
            >取 消</el-button
          >
          <el-button class="addBt" :disabled="buttonFlag" @click="sureAddElectronInfo(1)">确 定</el-button>
        </span>
      </el-dialog>      
    </div>
  </div>
</template>
<script>
import {
  payDrop,
  agnpayDrop,
  allDrop,
  getPlaceInfo,
  setPay,
  delPay,
  setUserPay,
  delUserPay,
  setSplitPay,
  delSplitPay,
} from "@/api/user/shopManager.js";
import { getAccountBookChannelDetail ,postAccountBookChannelAdd,delAccountBookChannel } from "@/api/trading/electron.js";
import { isTwoDecimal, isNumber } from "@/utils/common.js";
export default {
  data() {
    return {
      checkStatus: "", //商户审核状态,用来控制终端信息是否展示
      userPayShow: false, //新增支付展示
      hasPayShow: false, //新增代付展示
      accountShow: false, //新增分账展示
      payProducts: [], //支付产品列表
      agnpayProducts: [], //代付产品列表
      allocProducts: [], //分账产品列表
      formData: {}, //渠道信息
      payFormData: {
        //支付产品
        productId: "", //true	支付产品类型ID
        payChannelId: "", //true	支付产品通道ID
        refundStrategy: null, //true	退款策略
        settleCycle: "", //true	结算周期，
        settleRate: "", //true	结算费率%
        largeRate: "", //false	大额费率%
        dayLimit: "", //true	单日限额，单位分
        singleMinimumLimit: "", //true	单笔最小限额，单位分
        singleMaximumLimit: "", //true	单笔最大限额，单位分
        riskThreshold: "", //true	风控阈值（0-100）
        riskStatus: "", //true	风控状态
        payCodeStatus: 0, //是否指向其他产品类型 1为是 0为否 默认为否
        thisProductId: "", //其他产品类型ID
        thisProductValue: "", //其他产品类型名称
        riskGranularity:"", //统计粒度
        riskMinOrderNum:"", //最小预警订单数 
      },
      addFlag: false, //控制支付产品弹框新增的 是否指向其他产品类型和其他产品类型 两个选项的显示隐藏
      // 是否指向其他产品类型可选项
      directionList: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      // 其他产品类型可选项
      otherProduct: [],
      oldProductId: "", //被编辑的支付产品id
      payDropLists: [], //支付产品类型可选列表
      payDropListsChild: [], //支付产品通道列表
      editPayIndex: null,
      agnPayFormData: {
        //代付产品
        payType: "", //true	代付产品类型
        agnpayChannelId: "", //true	代付产品通道ID
        payRate: "", //true	代付费率%
        payCostPublic: "", //true	对公代付（单位分/笔）
        payCostPrivate: "", //true	对私代付（单位分/笔）
        dayLimit: "", //true	单日限额，单位分
        singleLimit: "", //true	单笔限额，单位分
      },
      oldPayType: "", //被编辑的代付产品type
      AgnpayDropLists: [], //代付产品类型列表
      AgnpayDropOption:{
        BANK_PAY_DAILY_ORDER:"朝夕付",
        BANK_PAY_MAT_ENDOWMENT_ORDER:"任意付",
      },
      AgnpayDropListsChild: [], //代付产品通道列表
      editAgnPayIndex: null,
      allFormData: {
        //分账展示
        settleType: "", //true	结算方式
        allocChannelId: "", //true	分账产品通道ID
        settleRate: "", //true	结算费率%
        settleCost: "", //true	结算成本（单位分/笔）
      },
      allDropLists: [], //结算方式列表
      allDropOption:{
        AUTO:"自动结算",
        MANUAL:"手工结算",
        MANUAL_INSTANT:"手工即时结算",
      },
      oldSettleType: "", //被编辑的分账产品类型
      allDropListsChild: [], //分账产品通道列表
      editAllIndex: null,
      OFFON_EDIT:"ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/EDIT",

      channelType:"", // 0 渠道信息-配置 1 电子账簿渠道信息-配置
      editElectronIndex:null,
      electronInfoShow:false,

      AccBooChannel:{},
      electronInfoData:{
        //电子账簿
        accountName:"",//户名
        accountNo:"",//账号
        accountBankName:"",//银行
        accountBranchBankName:"",//支行
        accountOpen:"",//开户地
        // 出金
        receiveAccountNo:"",//账号
        receiveAccountName:"",//出金户名
        receiveAccountBankName:"",//开户行

        aesKey: "",
        channelStatus: "FROZEN", // ACTIVATED（激活）、FROZEN（冻结）
        id:"",//商户Id
        mchChannelId:"",//商户号
        mchChannelName:"",//商户名称
        mchId: "",
        mchKey: "",
        mchPrivateKey: "",//商户RSA私钥
        mchPublicKey: "",//商户RSA公钥
        notifyUrl: "",//出金回调地址
        orgId: "",//所属机构
        orgPublicKey: "",//机构RSA公钥
      },

      buttonFlag: false, //防止重复提交
      editIncomeIndex:null,
      electronIncomeShow:false,
      mchId:"",

      fineness: [
        {
          title: "常规",
          value: "0",
          disabled: false,
        },
        {
          title: "1分钟",
          value: "1",
          disabled: false,
        },
        {
          title: "3分钟",
          value: "3",
          disabled: false,
        },
        {
          title: "5分钟",
          value: "5",
          disabled: false,
        },
      ],

    };
  },
  created() {
    this.channelType = this.$route.query.channelType;
    this.checkStatus = this.$route.query.checkStatus; //拿到商户审核状态
    // console.log("aaaa",this.checkStatus)
    this.mchId = this.$route.query.mchId;
    if(this.channelType == 0){
      this.getDetail();
    }else{
      this.getAccountBookDetail(this.mchId);  
    }
    this.listJurisdiction();
  },
  methods: {
    // 获取列表详情
    getDetail() {
      getPlaceInfo(
        this.$route.query.mchId,
        this.$route.query.mchChannelId
      ).then((res) => {
        if (res.resultStatus) {
          this.payProducts = res.resultData.payProducts;
          this.agnpayProducts = res.resultData.agnpayProducts;
          this.allocProducts = res.resultData.allocProducts;
          this.payProducts.forEach((val) => {
            val.dayLimit /= 100;
            val.singleMinimumLimit /= 100;
            val.singleMaximumLimit /= 100;
          });
          this.agnpayProducts.forEach((val) => {
            val.payCostPublic /= 100;
            val.payCostPrivate /= 100;
            val.dayLimit /= 100;
            val.singleLimit /= 100;
          });
          this.allocProducts.forEach((val) => {
            val.settleCost /= 100;
          });
          let orgId = res.resultData.mchChannelDetail.orgId;
          this.getPayDrop(orgId);
          this.getAgnpayDrop(orgId);
          this.getAllDrop(orgId);
        }
      });
    },
    // 获取支付下拉选择
    getPayDrop(orgId) {
      let data = {
        orgId: orgId,
        mchChannelId: "",
      };
      payDrop(data).then((res) => {
        this.payDropLists = res.resultData;
        this.otherProduct = [];
        this.payDropLists.forEach((v) => {
          v.disabled = false;
          if (v.productId == 13 || v.productId == 4) {
            this.otherProduct.push({
              value: v.productId,
              label: v.productName,
            });
          }
        });
        // console.log("其他产品类型可选数据", this.otherProduct);
      });
    },
    // 支付产品选择产品类型时
    payDropChange(value) {
      // console.log("支付产品选择产品类型时11", value);
      // console.log("支付产品选择产品类型时22", this.payFormData);

      this.payFormData.payChannelId = "";
      this.payFormData.payChannelName = "";
      this.payDropListsChild = [];
      this.payDropLists.forEach((v) => {
        if (v.productId === value) {
          this.payDropListsChild = v.payChannels;
          this.payFormData.productName = v.productName;
        }
      });
      console.log("支付产品类型改变时为", value);

      // 新增需求 当商户开通的产品类型为微信扫码+、微信H5支付、微信App支付、公众号支付、小程序支付时，可以配置实际请求机构的产品类型.
      if (
        value == 32 ||
        value == 3 ||
        value == 14 ||
        value == 4 ||
        value == 13
      ) {
        this.payFormData.payCodeStatus = 0;
        this.payFormData.thisProductId = "";
        this.payFormData.thisProductValue = "";
        this.addFlag = true;
      } else {
        this.addFlag = false;
        this.payFormData.payCodeStatus = 0;
      }
    },
    changePayCode(val) {
      if (val != 1) {
        this.payFormData.thisProductId = "";
      }
      // console.log("其他类型改变时为", val);
      // console.log("其他类型改变时为22", this.payFormData.payCodeStatus);
    },
    //获取电子账簿渠道信息
    getAccountBookDetail(mchId){
      getAccountBookChannelDetail(mchId).then((res)=>{
        if(res){
          if(res.resultData.mchChannel!=null){
            this.AccBooChannel = res.resultData.mchChannel;
          }else{
            this.AccBooChannel = {};
          }
        }       
      })
    },
    // 新增电子账簿
    addElectronInfo(type){
      if(type == 0){
        this.editElectronIndex = null;
        this.electronInfoShow = true;
      }else{
        this.editIncomeIndex = null;
        this.electronIncomeShow = true;
      }
      this.electronInfoData.mchId = this.mchId;
      this.electronInfoData = JSON.parse(JSON.stringify(this.AccBooChannel));
      
    },
    //编辑电子账簿渠道信息
    editElectronInfo(electronInfoData,type){
      if(type == 0){
         this.editElectronIndex = 1;
      }else{
        this.editIncomeIndex = 1;
      }
      console.log(type,"9999999")
      this.electronInfoData = JSON.parse(JSON.stringify(electronInfoData));
      this.oldBookKey = electronInfoData.mchKey;
      if(type == 0){
          this.electronInfoShow = true;
      }else{
          this.electronIncomeShow = true;
      }
      
    },
    //删除电子账簿渠道信息
    delElectronInfo(type){
      this.$confirm("此操作将删除该配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.electronInfoData = JSON.parse(JSON.stringify(this.AccBooChannel));
        if(type==0){
          this.electronInfoData.accountName = "";//户名
          this.electronInfoData.accountNo="";//账号
          this.electronInfoData.accountBankName="";//银行
          this.electronInfoData.accountBranchBankName="";//支行
          this.electronInfoData.accountOpen="";//开户地
        }else{
          this.electronInfoData.receiveAccountNo = "";//账号
          this.electronInfoData.receiveAccountName="";//出金户名
          this.electronInfoData.receiveAccountBankName="";//开户行         
        }
      
        let data = JSON.parse(JSON.stringify(this.electronInfoData));

        postAccountBookChannelAdd(this.mchId, data).then((res) => {
          if (res.resultStatus == true) {
            this.getAccountBookDetail(this.mchId);
            this.$message({
                type: "success",
                message: "删除成功!",
            });
          }
        })
      }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 确定新增/编辑电子账簿
    sureAddElectronInfo(type){ //type 为0 电子账簿 为1 出金信息
      let data = JSON.parse(JSON.stringify(this.electronInfoData));
      console.log(type);
      if(type==0){
          if (!data.accountName) {
            this.$message.error("户名必填");
            return false;
          }
          if (!data.accountNo) {
            this.$message.error("账号必填");
            return false;
          }
          if (!data.accountBankName) {
            this.$message.error("银行必填");
            return false;
          }      
          if (!data.accountBranchBankName) {
            this.$message.error("支行必填");
            return false;
          }
          if (!data.accountOpen) {
            this.$message.error("开户地必填");
            return false;
          }
      }else{
          if (!data.receiveAccountNo) {
            this.$message.error("账号必填");
            return false;
          }      
          if (!data.receiveAccountName) {
            this.$message.error("户名必填");
            return false;
          }
          if (!data.receiveAccountBankName) {
            this.$message.error("开户行必填");
            return false;
          }        
      }

      if (data.id) {
        if (data.mchKey === this.oldBookKey) {
          delete data.mchKey;
        }
      }      
      this.buttonFlag = true;
      data.id = data.id ? data.id : 0;
      postAccountBookChannelAdd(this.mchId, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
           this.getAccountBookDetail(this.mchId);
          if(type==0){
              this.electronInfoShow = false;
          }else{
              this.electronIncomeShow = false;
          }         
        }
        setTimeout(() => {
            this.buttonFlag = false;
        }, 2000);
      });     
    },
    // 新增支付
    addPayTab() {
      (this.payFormData = {
        productId: "",
        payChannelId: "",
        refundStrategy: "",
        settleCycle: "",
        settleRate: "",
        largeRate: "",
        dayLimit: "",
        singleMinimumLimit: "",
        singleMaximumLimit: "",
        riskThreshold: "",
        riskStatus: "",
        payCodeStatus: 0,
        thisProductId: "",
        thisProductValue: "",
        riskGranularity:"", //统计粒度
        riskMinOrderNum:"", //最小预警订单数 
      }),
        (this.userPayShow = true);
      this.editPayIndex = null;
      this.addFlag = false;
      this.payDropLists.forEach((val) => {
        val.disabled = false;
      });
      this.payProducts.forEach((v) => {
        this.payDropLists.forEach((val) => {
          if (v.productId == val.productId) {
            val.disabled = true;
          }
        });
      });
    },
    // 新增/编辑支付确认
    sureAddPayTab() {
      let data = JSON.parse(JSON.stringify(this.payFormData));

      console.log(data)

      // 判断有没有选择产品通道
      if (data.payChannelId) {
        this.payDropListsChild.forEach((e) => {
          if (data.payChannelId == e.payChannelId) {
            data.payChannelName = e.payChannelName;
          }
        });
      }
      if (!data.productId) {
        this.$message.error("产品类型必选");
        return false;
      }
      if(!data.payChannelId){
        this.$message.error("产品通道必选");
        return false;
      }
      if (!data.refundStrategy) {
        this.$message.error("退款策略必选");
        return false;
      }
      if (!data.settleCycle) {
        this.$message.error("结算周期必选");
        return false;
      }
      if (!data.settleRate && data.settleRate !== 0) {
        this.$message.error("结算费率必填");
        return false;
      } else {
        if (!isTwoDecimal(data.settleRate)) {
          this.$message.error("结算费率不合法(最多保留小数点后两位)");
          return false;
        }
      }
      if (data.settleRate < 0 || data.settleRate > 100) {
        this.$message.error("结算费率范围（0-100）");
        return false;
      }
      if (!data.largeRate && data.largeRate !== 0) {
        data.largeRate = data.settleRate;
      } else {
        if (data.largeRate < 0 || data.largeRate > 100) {
          this.$message.error("大额费率范围（0-100）");
          return false;
        }
        if (!isTwoDecimal(data.largeRate)) {
          this.$message.error("大额费率不合法(最多保留小数点后两位)");
          return false;
        }
      }
      if (!data.dayLimit && data.dayLimit !== 0) {
        this.$message.error("单日限额必填");
        return false;
      }
      if (data.dayLimit < 0) {
        this.$message.error("单日限额至少为0元");
        return;
      }
      if (!data.singleMinimumLimit && data.singleMinimumLimit !== 0) {
        this.$message.error("单笔最小限额必填");
        return false;
      }
      if (data.singleMinimumLimit < 0) {
        this.$message.error("单笔最小限额至少为0元");
        return;
      }
      if (!data.singleMaximumLimit && data.singleMaximumLimit !== 0) {
        this.$message.error("单笔最大限额必填");
        return false;
      }
      if (data.singleMaximumLimit < 0) {
        this.$message.error("单笔最大限额至少为0元");
        return;
      }
      if (!data.riskThreshold && data.riskThreshold !== 0) {
        this.$message.error("风控阈值必填");
        return false;
      } else {
        if (!isNumber(data.riskThreshold)) {
          this.$message.error("风控阈值不合法(请输入0-100正整数)");
          return false;
        }
      }
      if (data.riskThreshold < 0 || data.riskThreshold > 100) {
        this.$message.error("风控阈值范围（0-100）");
        return false;
      }
      if (data.payCodeStatus == 1 && !data.thisProductId) {
        this.$message.error("其他产品类型必选");
        return false;
      }

      if (!data.riskGranularity) {
        this.$message.error("统计粒度必选");
        return false;
      }
      if (!data.riskMinOrderNum && data.riskMinOrderNum !== 0) {
        this.$message.error("最小预警订单数必填");
        return false;
      }
      if (data.riskMinOrderNum < 0 || !/^[0-9]*$/.test(data.riskMinOrderNum)) {
        this.$message.error("最小预警订单数至少为0,且为整数");
        return;
      }

      if(data.dayLimit.length > 8 || data.singleMinimumLimit.length > 8 || data.singleMaximumLimit.length > 8){        
        this.$message.error("金额超过最大长度");
        return false;
      }
      if(!(/^(\d?)+(\.\d{0,2})?$/.test(data.dayLimit)) || !(/^(\d?)+(\.\d{0,2})?$/.test(data.singleMinimumLimit)) || !(/^(\d?)+(\.\d{0,2})?$/.test(data.singleMaximumLimit))){
        this.$message.error("金额只能保留到小数点后两位");
        return false;
      }

      if (data.riskStatus == "") {
        data.riskStatus = false;
      }

      if(this.payFormData.riskGranularity == "1分钟" || this.payFormData.riskGranularity == 1){
        data.riskGranularity = 1
      }else if(this.payFormData.riskGranularity == "3分钟" || this.payFormData.riskGranularity == 3){
        data.riskGranularity = 3
      }else if(this.payFormData.riskGranularity == "5分钟" || this.payFormData.riskGranularity == 5){
        data.riskGranularity = 5
      }else{
        data.riskGranularity = 0
      }

      data.productId = data.productId ? data.productId : 0;
      data.dayLimit *= 100;
      data.singleMinimumLimit *= 100;
      data.singleMaximumLimit *= 100;
      data.riskThreshold = Number(data.riskThreshold);
      let productId =
        this.editPayIndex == null ? data.productId : this.oldProductId;

      data.payCodeStatus == 1
        ? (data.payCodeStatus = true)
        : (data.payCodeStatus = false);
      if (!data.payCodeStatus) {
        (data.thisProductId = ""), (data.thisProductValue = "");
      }
      // 拿到 其他产品类型名称
      if (data.thisProductId) {
        this.otherProduct.forEach((e) => {
          if (e.value == data.thisProductId) {
            data.thisProductValue = e.label;
          }
        });
      } else {
        data.thisProductId = "";
        data.thisProductValue = "";
      }
      console.log("新增或编辑时提交的数据", data);

      this.buttonFlag = true;
      setPay(
        this.$route.query.mchId,
        this.$route.query.mchChannelId,
        productId,
        data
      ).then((res) => {
        if (res.resultStatus) {
          if (this.editPayIndex == null) {
            this.$message.success("新增支付产品成功");
          } else {
            this.$message.success("编辑支付产品成功");
          }
          this.getDetail();
          this.userPayShow = false;
          this.editPayIndex = null;
        }
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
      });
    },
    // 关闭支付弹窗
    onCloseAccount(){
      this.userPayShow = false;
      this.editPayIndex = null;
    },
    // 修改风控状态
    setStatus(data) {
      // return
      const obj = { ...data };
      obj.dayLimit *= 100;
      obj.singleMinimumLimit *= 100;
      obj.singleMaximumLimit *= 100;
      console.log("切换支付产品风控状态", obj);
      console.log(this.editPayIndex)
      let productId =
        this.editPayIndex == null ? data.productId : this.oldProductId;
      // return
      setPay(
        this.$route.query.mchId,
        this.$route.query.mchChannelId,
        productId,
        obj
      ).then((res) => {
        if (!res.resultStatus) {
          data.riskStatus = data.riskStatus ? false : true;
        }
      });
    },
    // 点击编辑支付产品时
    editPayTab(value) {
      this.payFormData.dayLimit = value.dayLimit; //true	单日限额，单位分
      this.payFormData.productId = value.productId; //true	支付产品类型ID
      this.payFormData.payChannelId = value.payChannelId; //true	支付产品通道ID
      this.payFormData.refundStrategy = value.refundStrategy; //true	退款策略
      this.payFormData.settleCycle = value.settleCycle; //true	结算周期，
      this.payFormData.settleRate = value.settleRate; //true	结算费率%
      this.payFormData.largeRate = value.largeRate; //false	大额费率%
      this.payFormData.singleMinimumLimit = value.singleMinimumLimit; //true	单笔最小限额，单位分
      this.payFormData.singleMaximumLimit = value.singleMaximumLimit; //true	单笔最大限额，单位分
      this.payFormData.riskThreshold = value.riskThreshold; //true	风控阈值（0-100）
      this.payFormData.riskStatus = value.riskStatus; //true	风控状态


      this.payFormData.riskGranularity = value.riskGranularity;
      this.payFormData.riskMinOrderNum = value.riskMinOrderNum;

      if(value.riskGranularity == 1){
        this.payFormData.riskGranularity = "1分钟"
      }else if(value.riskGranularity == 3){
        this.payFormData.riskGranularity = "3分钟"
      }else if(value.riskGranularity == 5){
        this.payFormData.riskGranularity = "5分钟"
      }else{
        this.payFormData.riskGranularity = "常规"
      }

      // 新增的需求数据
      this.payFormData.payCodeStatus = value.payCodeStatus ? 1 : 0; //是否指向其他产品类型 1为是 0为否 默认为否
      this.payFormData.thisProductId =
        this.payFormData.payCodeStatus == 1 ? value.thisProductId : ""; //其他产品类型ID
      this.payFormData.thisProductValue =
        this.payFormData.payCodeStatus == 1 ? value.thisProductValue : ""; //其他产品类型名称

      
      console.log("支付产品类型", value);

      this.oldProductId = this.payFormData.productId;
      this.editPayIndex = 1;
      this.userPayShow = true;
      this.payDropListsChild = [];

      this.payDropLists.forEach((v) => {
        v.disabled = false;
        if (v.productId === this.payFormData.productId) {
          this.payDropListsChild = v.payChannels;
        }
        this.payProducts.forEach((h) => {
          if (v.productId == h.productId) {
            v.disabled = true;
          }
        });
      });

      // 新增需求 当商户开通的产品类型为微信扫码+、微信H5支付、微信App支付、公众号支付、小程序支付时，可以配置实际请求机构的产品类型.
      if (
        value.productId == 32 ||
        value.productId == 3 ||
        value.productId == 14 ||
        value.productId == 4 ||
        value.productId == 13
      ) {
        this.addFlag = true;
      } else {
        this.addFlag = false;
      }
    },
    // 删除支付
    delPayTab(id) {
      this.$confirm("此操作将删除该支付产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delPay(
            this.$route.query.mchId,
            this.$route.query.mchChannelId,
            id
          ).then((res) => {
            if (res.resultStatus) {
              this.getDetail();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取代付下拉选择
    getAgnpayDrop(orgId) {
      let data = {
        orgId: orgId,
        mchChannelId: "",
      };
      agnpayDrop(data).then((res) => {
        this.AgnpayDropLists = res.resultData;
      });
    },
    // 代付下拉选择
    agnPayDropChange(value) {
      this.agnPayFormData.agnpayChannelId = "";
      this.AgnpayDropListsChild = [];
      this.AgnpayDropLists.forEach((v) => {
        if (v.payType === value) {
          this.AgnpayDropListsChild = v.agnpayChannels;
        }
      });
    },
    // 新增代付
    addAgnPayTab() {
      (this.agnPayFormData = {
        payType: "",
        agnpayChannelId: "",
        payRate: "",
        payCostPublic: "",
        payCostPrivate: "",
        dayLimit: "",
        singleLimit: "",
      }),
        (this.hasPayShow = true);
      this.editAgnPayIndex = null;
      this.AgnpayDropLists.forEach((val) => {
        val.disabled = false;
      });
      this.agnpayProducts.forEach((v) => {
        this.AgnpayDropLists.forEach((val) => {
          if (v.payType == val.payType) {
            val.disabled = true;
          }
        });
      });
    },
    // 新增/修改代付确认
    sureAddAgnPayTab() {
      let data = JSON.parse(JSON.stringify(this.agnPayFormData));
      if (!data.payType) {
        this.$message.error("产品类型必选");
        return false;
      }
      if(!data.agnpayChannelId){
        this.$message.error("产品通道必选");
        return false;
      }
      if (!data.payRate && data.payRate !== 0) {
        this.$message.error("代付费率必填");
        return false;
      } else {
        if (!isTwoDecimal(data.payRate)) {
          this.$message.error("代付费率不合法(最多保留小数点后两位)");
          return false;
        }
      }
      if (data.payRate < 0 || data.payRate > 100) {
        this.$message.error("代付费率范围（0-100）");
        return false;
      }
      if (!data.payCostPublic && data.payCostPublic !== 0) {
        this.$message.error("对公代付必填");
        return false;
      }
      if (data.payCostPublic < 0) {
        this.$message.error("对公代付金额至少为0元");
        return;
      }
      if (!data.payCostPrivate && data.payCostPrivate !== 0) {
        this.$message.error("对私代付必填");
        return false;
      }
      if (data.payCostPrivate < 0) {
        this.$message.error("对私代付金额至少为0元");
        return;
      }
      if (!data.dayLimit && data.dayLimit !== 0) {
        this.$message.error("单日限额必填");
        return false;
      }
      if (data.dayLimit < 0) {
        this.$message.error("单日限额至少为0元");
        return;
      }
      if (!data.singleLimit && data.singleLimit !== 0) {
        this.$message.error("单笔限额必填");
        return false;
      }
      if (data.singleLimit < 0) {
        this.$message.error("单笔限额至少为0元");
        return;
      }

      if(data.payCostPublic.length > 8 || data.payCostPrivate.length > 8 || data.dayLimit.length > 8 || data.singleLimit.length > 8){        
        this.$message.error("金额超过最大长度");
        return false;
      }
      if(!(/^(\d?)+(\.\d{0,2})?$/.test(data.payCostPublic)) || !(/^(\d?)+(\.\d{0,2})?$/.test(data.payCostPrivate)) || !(/^(\d?)+(\.\d{0,2})?$/.test(data.dayLimit)) || !(/^(\d?)+(\.\d{0,2})?$/.test(data.singleLimit))){
        this.$message.error("金额只能保留到小数点后两位");
        return false;
      }

      data.agnpayChannelName = "";
      data.payCostPublic *= 100;
      data.payCostPrivate *= 100;
      data.dayLimit *= 100;
      data.singleLimit *= 100;

      let oldType =
        this.editAgnPayIndex == null ? data.payType : this.oldPayType;

      this.buttonFlag = true;
      setUserPay(
        this.$route.query.mchId,
        this.$route.query.mchChannelId,
        oldType,
        data
      ).then((res) => {
        if (res.resultStatus) {
          if (this.editAgnPayIndex == null) {
            this.$message.success("新增代付产品成功");
          } else {
            this.$message.success("编辑代付产品成功");
          }
          this.getDetail();
          this.hasPayShow = false;
          setTimeout(() => {
            this.buttonFlag = false;
          }, 2000);
        }
      });
    },
    // 修改代付
    editAgnPayTab(value, index) {
      this.agnPayFormData = JSON.parse(JSON.stringify(value));
      this.oldPayType = this.agnPayFormData.payType;
      // console.log("被编辑的代付数据",   this.agnPayFormData);

      this.hasPayShow = true;
      this.editAgnPayIndex = index;
      this.AgnpayDropListsChild = [];
      this.AgnpayDropLists.forEach((v) => {
        if (v.payType === this.agnPayFormData.payType) {
          this.AgnpayDropListsChild = v.agnpayChannels;
        }
      });
      this.AgnpayDropLists.forEach((val) => {
        val.disabled = false;
      });
      this.agnpayProducts.forEach((v) => {
        this.AgnpayDropLists.forEach((val) => {
          if (v.payType == val.value) {
            val.disabled = true;
          }
        });
      });
    },
    // 删除代付
    delAgnPayTab(type) {
      this.$confirm("此操作将删除该代付产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delUserPay(
            this.$route.query.mchId,
            this.$route.query.mchChannelId,
            type
          ).then((res) => {
            if (res.resultStatus) {
              this.getDetail();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取分账下拉选择
    getAllDrop(orgId) {
      let data = {
        orgId: orgId,
        mchChannelId: "",
      };
      allDrop(data).then((res) => {
        this.allDropLists = res.resultData;
      });
    },
    // 分账下拉选择
    allDropChange(value) {
      this.allFormData.allocChannelId = "";
      this.allDropListsChild = [];
      this.allDropLists.forEach((v) => {
        if (v.settleType === value) {
          this.allDropListsChild = v.allocChannels;
        }
      });
    },
    // 新增分账
    addAllTab() {
      (this.allFormData = {
        settleType: "",
        allocChannelId: "",
        settleRate: "",
        settleCost: "",
      }),
        (this.accountShow = true);
      this.editAllIndex = null;
      this.allDropLists.forEach((val) => {
        val.disabled = false;
      });
      this.allocProducts.forEach((v) => {
        this.allDropLists.forEach((val) => {
          if (v.settleType == val.settleType) {
            val.disabled = true;
          }
        });
      });
    },
    // 新增分账确认
    sureAddAllTab() {
      let data = JSON.parse(JSON.stringify(this.allFormData));
      if (!data.settleType) {
        this.$message.error("产品类型必选");
        return false;
      }
      if(!data.allocChannelId){
        this.$message.error("产品通道必选");
        return false;
      }
      if (!data.settleRate && data.settleRate !== 0) {
        this.$message.error("结算费率必填");
        return false;
      } else {
        if (!isTwoDecimal(data.settleRate)) {
          this.$message.error("结算费率不合法(最多保留小数点后两位)");
          return false;
        }
      }
      if (data.settleRate < 0 || data.settleRate > 100) {
        this.$message.error("结算费率范围（0-100）");
        return false;
      }
      if (!data.settleCost && data.settleCost !== 0) {
        this.$message.error("结算成本必填");
        return false;
      }
      if(data.settleCost.length > 8){        
        this.$message.error("金额超过最大长度");
        return false;
      }
      if(!(/^(\d?)+(\.\d{0,2})?$/.test(data.settleCost))){
        this.$message.error("金额只能保留到小数点后两位");
        return false;
      }
      // if (data.settleCost < 0.01) {
      // 	this.$message.error('结算成本金额至少为0.01元')
      // 	return;
      // }
      data.allocChannelName = "";
      data.settleCost *= 100;
      // console.log("编辑新增分账产品", data);
      let settleType =
        this.editAllIndex == null ? data.settleType : this.oldSettleType;
      // return
      this.buttonFlag = true;
      setSplitPay(
        this.$route.query.mchId,
        this.$route.query.mchChannelId,
        settleType,
        data
      ).then((res) => {
        if (res.resultStatus) {
          if (this.editAllIndex == null) {
            this.$message.success("新增分账产品成功");
          } else {
            this.$message.success("编辑分账产品成功");
          }
          this.getDetail();
          this.accountShow = false;
        }
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
      });
    },
    // 修改分账
    editAllTab(value, index) {
      this.allFormData = JSON.parse(JSON.stringify(value));
      this.oldSettleType = this.allFormData.settleType;
      this.accountShow = true;
      this.editAllIndex = index;
      this.allDropListsChild = [];
      this.allDropLists.forEach((v) => {
        if (v.settleType === this.allFormData.settleType) {
          this.allDropListsChild = v.allocChannels;
        }
      });
      this.allDropLists.forEach((val) => {
        val.disabled = false;
      });
      this.allocProducts.forEach((v) => {
        this.allDropLists.forEach((val) => {
          if (v.settleType == val.settleType) {
            val.disabled = true;
          }
        });
      });
    },
    // 删除分账
    delAllTab(type) {
      this.$confirm("此操作将删除该分账产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delSplitPay(
            this.$route.query.mchId,
            this.$route.query.mchChannelId,
            type
          ).then((res) => {
            if (res.resultStatus) {
              this.getDetail();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 返回
    back() {
      this.$router.go(-1);
    },
    //判断列表按钮是否有权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
        if(_resources=='ALL'){
            this.OFFON_EDIT = 'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/EDIT';
        }else{
          if(_resources.split(';').indexOf('ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/EDIT') !== -1 ){
            this.OFFON_EDIT = 'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/EDIT';
          }else{
            this.OFFON_EDIT = "";
          }
        }

    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}

.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}
.back:hover {
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  color: #fff;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
  margin-top: 50px;
  margin-bottom: 18px;
}
.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}
</style>
